//mp form actions
export const UPDATE_MP_FORM_STATE = 'UPDATE_MP_FORM_STATE';
export const ADD_MP_FORM_TAB = 'ADD_MP_FORM_TAB';
export const REMOVE_MP_FORM_TAB = 'REMOVE_MP_FORM_TAB';
export const UPDATE_MP_FORM_RESULTS = 'UPDATE_MP_FORM_RESULTS';

//dmg form actions
export const UPDATE_DMG_FORM_STATE = 'UPDATE_DMG_FORM_STATE';
export const ADD_DMG_FORM_TAB = 'ADD_DMG_FORM_TAB';
export const REMOVE_DMG_FORM_TAB = 'REMOVE_DMG_FORM_TAB';
export const UPDATE_DMG_FORM_RESULTS = 'UPDATE_DMG_FORM_RESULTS';
